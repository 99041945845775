import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NumbersAngajati from "../components/numbers-angajati"
import Piloni from "../components/piloni"

import HeroAngajati from "../images/hero-angajati-si-societate.jpg"
import HeroAngajatiM from "../images/hero-angajati-si-societate-m.jpg"

import BunastareaAngajatilor from "../images/bunastarea-angajatilor.jpg"
import ResponsabilitateSociala from "../images/responsabilitate-sociala.jpg"
import EducatieSiConstientizare from "../images/educatie-si-constientizare.jpg"
import MamagementulRisipeiAlimentare from "../images/managementul-risipei-alimentare.jpg"
import ComunitatiLocale from "../images/comunitati-locale.jpg"

import DownloadIcon from "../images/download.svg"

import Submenu from "../components/submenu"

const AngajatiPage = () => (
  <Layout pageInfo={{ pageName: "angajati" }}>
    <SEO title="Angajati si societate" />
    <section className="bg-light">
      <div className="hero text-center mx-auto">
        <img className="d-none d-lg-block w-100" src={HeroAngajati} alt="" />
        <img className="d-lg-none w-100" src={HeroAngajatiM} alt="" />
      </div>
    </section>
    <div className="container">
      <div className="row mt-5">
        <div className="col-10 my-5 mx-auto">
          <h1 className="text-success text-center akko-bold py-3">Cultura noastră este definită de  modul în care ne tratăm oamenii!</h1>
          <p className="akko-regular text-center">
            Ne concentrăm pe bunăstarea angajaților noștri și, în egală măsură, ne tratăm clienții cu  respect și responsabilitate. Obiectivele noastre în cadrul acestui pilon sunt crearea de posibilități de  integrare socială și dezvoltare economică, oferirea de soluții pentru provocările socio-economice și imaginea de bun cetățean.
            <br/>
            I. Dezvoltarea angajaților
            <br/>
            II. Responsabilitate socială (CSR)
            <br/>
            III. Managementul risipei alimentare
          </p>
        </div>
        <div className="col-12 pb-5 text-center">
          <a
            href="/raport-de-sustenabilitate-2022"
            className="btn btn-primary akko-bold mx-auto"
          >
            Descarca raport
            <img className="icon-badge-white ml-2" src={DownloadIcon} alt="" />
          </a>
        </div>
      </div>
    </div>

    <NumbersAngajati />

    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${BunastareaAngajatilor})`,
            backgroundPosition: "bottom left",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3 text-uppercase">Bunăstarea angajaților</h1>
          <p className="mb-0">
            Angajații constituie pilonul central al activității noastre de  zi cu zi. De aceea ne îndreptăm eforturile spre atragerea  oamenilor ambițioși, talentați, capabili de performanță  și cu o pregătire solidă în domeniul lor de activitate.  Asigurăm un mediu de lucru sigur, dinamic și încurajăm  dezvoltarea în direcția cea mai bună pentru fiecare  angajat, în cadrul unei structuri organizaționale flexibile  care are în centrul atenției angajatul. Oferim șansa de a  face parte din echipa noastră și persoanelor care aparțin  unor grupuri vulnerabile și care reușesc din ce în ce mai  greu să se integreze pe piața actuală a muncii, cum sunt  persoanele la început de carieră, persoanele trecute de  vârsta de 50 ani și persoanele cu dizabilități.
            <br/><br/>
            Ne bazăm pe gestionarea strategică a resurselor umane  cu ajutorul unor concepte de lucru adaptate la nevoile  angajaților, condiții de lucru sigure, oportunități de  formare personalizate și aprecierea fiecărui angajat în  parte. Din punct de vedere operațional, implementăm  procese riguroase prin care angajații noștri sunt implicați  în activități specifice. Scopul acestor activități este de  a diminua riscurile și a crea oportunități, atât pentru  afacere, cât și pentru angajați. Abordarea noastră internă  se concentrează pe: Recrutarea și retenția angajaților; Politicile de resurse umane și beneficiile acordate  angajaților noștri; Politicile de resurse umane orientate către diverse  etape importante din viața angajaților; Diversitatea și egalitatea de șanse; Oportunitățile pentru persoanele trecute de 50 de  ani; Programele de educație și training pentru angajați; Programele de wellbeing; Sănătatea și siguranța la locul de muncă.
          </p>
        </div>
      </div>
    </div>

    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3 text-uppercase">Responsabilitate socială</h1>
          <p className="mb-0">
            Ca orice companie mare, responsabilitatea noastră față de societate merge dincolo de businessul nostru  principal. Dacă ne dorim o societate mai bună atât din punct de vedere economic, cât și social, este nevoie să ne implicăm cu toții și să susținem cauzele care pot determina schimbarea. 
            <br/><br/>
            Să fim o companie sustenabilă înseamnă să ne implicăm activ în cauzele care au nevoie de susținere. Acționăm  cu grijă față de comunități și susținem dezvoltarea  acestora prin intermediul ONG-urilor cu care am dezvoltat parteneriate. Implicarea noastră în cauzele sociale se bazează pe investirea resurselor în mod inteligent și relevant pentru misiunea noastră. În 2022 am dezvoltat  un studiu SROI (Social Return on Investment) pentru proiectele PENNY, pentru a înțelege impactul real al  acțiunilor noastre de responsabilitate socială, <a href="/documente/03.08.23 SROI EN Women.pdf">disponibil aici</a>.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${ResponsabilitateSociala})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </div>
    </div>

    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${EducatieSiConstientizare})`,
            backgroundPosition: "top center",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3 text--uppercase">
            Educație și conștientizare
          </h1>
          <p className="mb-0">
            Ne dorim să susținem comunitățile în care ne desfășurăm activitatea și de aceea contribuim an de an la implementarea unor programe care susțin dezvoltarea  copiilor și tinerilor. Încă din 2017 participăm activ la  susținerea învățământului dual în România și oferim  programe de stagiatură tinerilor care vor să înceapă o carieră în retail. Investițiile în pregătirea practică a tinerilor facilitează integrarea acestora pe piața muncii, având inclusiv oportunitatea de a deveni colegii noștri.  De asemenea, implicarea noastră în proiecte de susținere a elevilor, prin burse și oportunități de practică, urmărește să îmbunătățească oportunitățile educaționale din comunitățile în care activăm și promovează șanse egale la educație pentru tinerii din medii dezavantajate.
            <br/><br/>
            Promovăm educația și dezvoltarea copiilor și a tinerilor,  aceasta fiind o parte integrată a strategiei noastre de CSR  și un obiectiv important pentru misiunea și identitatea  businessului nostru.  Afla mai multe <a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/219/">aici</a>.
          </p>
        </div>
      </div>
    </div>

    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3 text--uppercase">
            Comunități locale
          </h1>
          <p className="mb-0">
            Suntem alături de comunitățile locale. Împreună cu  partenerii noștri ne implicăm activ în promovarea  sănătății, susținerea persoanelor dezavantajate din punct de vedere social și suntem alături de comunitățile locale prin sprijinirea investițiilor în infrastructură. Încurajăm  participarea angajaților noștri în proiectele pe care le desfășurăm prin acțiuni de voluntariat și susținerea  clienților prin campaniile desfășurate în magazine.  Considerăm că doar astfel putem consolida spiritul  comunitar și extinde impactul pozitiv al proiectelor.
            <br/><br/>
            Stabilitatea și dezvoltarea afacerii noastre depind de asigurarea unui nivel ridicat de bunăstare a comunităților  în care ne desfășurăm activitatea. Obiectivele noastre de  implicare în comunitate urmăresc crearea unui mediu  propice pentru creșterea și dezvoltarea copiilor, asigurarea  securității alimentare, și creșterea posibilităților pentru  ceea ce noi numim „sustainable lifestyle“. 
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${ComunitatiLocale})`,
            backgroundPosition: "top center",
            backgroundSize: "cover",
          }}
        />
      </div>
    </div>
    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-1 order-lg-1">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${MamagementulRisipeiAlimentare})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div className="col-12 col-lg-6 order-2 order-lg-2">
        <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3 text--uppercase">
            Managementul Risipei Alimentare
          </h1>
          <p className="m-0">
            Reducerea risipei alimentare reprezintă o prioritate pentru PENNY România, aceasta fiind una dintre cele mai semnificative probleme globale din perspectiva impactului negativ asupra societății și mediului. Companiile din industria de retail de produse alimentare sunt, din această perspectivă, principalii actori responsabili datorită rolului pe care îl joacă în distribuția și vânzarea acestor produse. Reducerea risipei alimentare  nu este doar o obligație morală și legală, ci și o metodă  de a genera impact pozitiv atât economic, cât și social și  pentru mediul înconjurător. 
            <br/><br/>
            Astfel, prin analiza proceselor și provocărilor  care apar pe întregul lanț de aprovizionare, au fost  implementate soluții de diminuare a risipei alimentare în  4  arii de acțiune:
            <br/><br/>
            <ul>
              <li><a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/223/">Reducerea risipei alimentare rezultate din operațiunile PENNY</a></li>
              <li><a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/225/">Direcționarea surplusului de alimente către organizații caritabile</a></li>
              <li><a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/228/">Deturnarea alimentelor care nu mai sunt potrivite  pentru consumul uman</a></li>
              <li><a href="https://sustenabilitate.penny.ro/raport-de-sustenabilitate-2022/2/">Educarea consumatorului prin campanii de conștietizare</a></li>
            </ul>
          </p>
        </div>
      </div>
    </div>
    <div className="container pt-5">
      <Piloni hide="3" />
    </div>
  </Layout>
)

export default AngajatiPage
